import React from "react"

class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index:
        typeof props.selectedIndex !== "undefined" ? props.selectedIndex : -1,
    }
    this.nodes = []
  }

  componentWillReceiveProps(props) {
    if (
      typeof props.selectedIndex !== "undefined" &&
      this.state.index !== props.selectedIndex
    ) {
      this.toggle(props.selectedIndex)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  toggle(index, click) {
    clearTimeout(this.timeout)

    if (click) {
      if (this.props.onChange)
        this.props.onChange(
          index,
          this.state.index !== index,
          this.state.index !== index ? index : -1
        )
      if (!this.props.changeOnClick) return
    }

    if (this.state.index > -1) {
      const content = this.nodes[this.state.index].ref.children[1]
      content.style.height = `${content.children[0].offsetHeight}px` // Set fixed height before collapse of current open item
    }

    if (this.state.index === index || index === -1) {
      setTimeout(() => {
        this.setState({ index: -1 })
      }, 50)
    } else {
      setTimeout(() => {
        this.setState({ index })
        this.timeout = setTimeout(() => {
          this.nodes[index].ref.children[1].style.height = "auto" // Set auto height after expand
        }, this.props.transitionDuration)
      }, 50)
    }
  }

  getHeight(index) {
    if (index === this.state.index) {
      //   return this.nodes.length > index
      //     ? this.nodes[index].ref.children[1].children[0].offsetHeight
      //     : "auto"
      return "auto"
    }
    return 0
  }

  render() {
    const style = {
      overflow: "hidden",
      transition: `height ${this.props.transitionDuration}ms ${this.props.transitionTimingFunction}`,
    }
    const nodes = React.Children.map(this.props.children, (child, index) => (
      <div
        key={index}
        ref={div => {
          this.nodes[index] = { ref: div }
        }}
        className={this.state.index === index ? this.props.openClassName : ""}
      >
        <div onClick={() => this.toggle(index, true)}>
          <span>
            {index === this.state.index ? (
              <svg
                className="svg minus"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0"
                y="0"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                enableBackground="new 0 0 19 19"
                xmlSpace="preserve"
                aria-hidden="true"
              >
                <path d="M8 8C5.2 8 2.6 8 0 8c0 1 0 2 0 3 2.5 0 4.9 0 8 0 2.8 0 8.4 0 11 0 0-1 0-2 0-3C16.5 8 11.1 8 8 8z"></path>
              </svg>
            ) : (
              <svg
                className="svg plus"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0"
                y="0"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                enableBackground="new 0 0 19 19"
                xmlSpace="preserve"
                aria-hidden="true"
              >
                <path d="M11 19c-1 0-2 0-3 0 0-2.6 0-5.2 0-8 -3.1 0-5.5 0-8 0 0-1 0-2 0-3 2.6 0 5.2 0 8 0 0-3.1 0-5.5 0-8 1 0 2 0 3 0 0 2.6 0 5.2 0 8 3.1 0 5.5 0 8 0 0 1 0 2 0 3 -2.6 0-5.2 0-8 0C11 14.1 11 16.5 11 19z"></path>
              </svg>
            )}
          </span>

          <span style={{ marginLeft: 10 }}>{child.props["data-header"]}</span>
        </div>
        <div style={{ ...style, height: this.getHeight(index) }}>{child}</div>
      </div>
    ))
    return <div className={this.props.className}>{nodes}</div>
  }
}

Accordion.defaultProps = {
  transitionDuration: 500,
  transitionTimingFunction: "ease",
  openClassName: "open",
  changeOnClick: true,
}

export default Accordion
