import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FaStar, FaRegStar } from "react-icons/fa"

const Reviews = ({ handle }) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          nodes {
            frontmatter {
              handle
              reviews {
                date(formatString: "MMMM DD, YYYY")
                author
                stars
                comment
              }
            }
          }
        }
      }
    `
  )
  const nodes = allMarkdownRemark.nodes.filter(
    node => node.frontmatter.handle === handle
  )
  let reviews = []
  if (nodes.length > 0) {
    reviews = nodes[0].frontmatter.reviews
  }

  const displayStars = stars => {
    const display = []
    for (let i = 0; i < stars; i++) {
      display.push(
        <FaStar key={`${i}${Date.now()}${Math.random()}`} color="#02b0f1" />
      )
    }

    const difference = 5 - stars

    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        display.push(
          <FaRegStar
            key={`${i}${Date.now()}${Math.random()}`}
            color="#02b0f1"
          />
        )
      }
    }

    return display
  }

  return (
    <section className="section">
      <div className="container">
        <h3 className="has-text-centered">
          <FaStar color="#02b0f1" />
          <FaStar color="#02b0f1" />
          <FaStar color="#02b0f1" />
          <FaStar color="#02b0f1" />
          <FaStar color="#02b0f1" />
          <b> {/*reviews.length*/}173 + Orders</b>
        </h3>

        <div className="columns is-multiline" style={{ marginTop: 20 }}>
          {reviews.map(review => (
            <div
              key={`${review.comment}${Date.now()}`}
              className="column is-12"
            >
              <div key={`${review.comment}${Date.now()}`} className="box">
                <div className="content">
                  <p>
                    <strong>{review.author}</strong>{" "}
                    <small>{displayStars(review.stars)}</small>
                    <br />
                    {review.comment}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Reviews
