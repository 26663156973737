import React, { Component } from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Reviews from "../components/Reviews"
import RelatedProducts from "../components/RelatedProducts"
import freelancer from "../images/svg/freelancer.svg"
import focus from "../images/svg/focus.svg"
import happy_birthday_gift from "../images/svg/happy_birthday_gift.svg"

import Accordion from "../components/Accordion"

class ProductTemplate extends Component {
  componentDidMount() {
    const {
      shopifyProduct: { title, shopifyId, images, priceRange },
      shopifyCollection
    } = this.props.data

    if (process.env.NODE_ENV === "production") {
      if (window.fbq) {
        window.fbq("track", "ViewContent", {
          value: 39.99,
          currency: "USD",
        })
      }
    }

    //if (process.env.NODE_ENV === "production") {
    const item = {
      ProductName: title,
      ProductID: shopifyId,
      Categories: [shopifyCollection.title],
      ImageURL: images[0].originalSrc,
      URL: `https://waggtopia.com/product/${this.props.pageContext.handle}`,
      Brand: "Waggtopia",
      Price: parseFloat(priceRange.minVariantPrice.amount),
      CompareAtPrice: parseFloat(priceRange.minVariantPrice.amount) * 2,
    }

    if (window._learnq) {
      window._learnq.push(["track", "Viewed Product", item])

      window._learnq.push([
        "trackViewedItem",
        {
          Title: item.ProductName,
          ItemId: item.ProductID,
          Categories: item.Categories,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price,
            CompareAtPrice: item.CompareAtPrice,
          },
        },
      ])
    }
    //}
  }
  render() {
    const {
      product: { frontmatter },
      beach,
      sunset,
      prehistoric_jungle,
      customers,
    } = this.props.data

    console.log('fff', this)

    const faqs = frontmatter.faq
    return (
      <Layout>
        <SEO
          title={frontmatter.seo.title}
          description={frontmatter.seo.description}
        />
        <section className="hero">
          <div className="hero-body">
            <div className="container" style={{ maxWidth: 3200 }}>
              <div className="columns is-vcentered">
                <div className="column is-6">
                  <figure>
                    <Img
                      style={{ borderRadius: 10 }}
                      fluid={frontmatter.section1.img.childImageSharp.fluid}
                    />
                  </figure>
                </div>
                <div className="column is-6">
                  <h1
                    className="title has-text-centered is-uppercase	is-2"
                    style={{ marginBottom: 50 }}
                  >
                    {frontmatter.section1.title}
                  </h1>
                  <h2 className="subtitle has-text-centered">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.section1.content,
                      }}
                    />
                  </h2>
                  <Link
                    state={{
                      showUpgrade: true
                    }}
                    to={frontmatter.callToAction.uri}
                    onClick={() => {
                      if (process.env.NODE_ENV === "production") {
                        if (window.fbq) {
                          window.fbq("track", "CustomizeProduct", {
                            value: 39.99,
                            currency: "USD",
                          })
                        }
                      }
                    }}
                  >
                    <p className="has-text-centered">
                      <button className="button is-primary is-large">
                        {frontmatter.callToAction.title}
                      </button>
                    </p>
                  </Link>
                </div>
              </div>

              {/* <div
                className="columns is-vcentered has-text-centered is-multiline"
                style={{ marginTop: 30 }}
              >
                <div className="column is-12">
                  <Img
                    className=""
                    fixed={christmas_guarantee.childImageSharp.fixed}
                  />
                </div>
                <div className="column is-12">
                  <p
                    className="has-text-centered is-size-4 has-text-weight-bold"
                    style={{ color: "#02b0f1" }}
                  >
                    GUARANTEED TO ARRIVE BEFORE CHRISTMAS OR YOUR MONEY
                    BACK!
                      </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <hr />
        <section className="hero is-medium">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1
                className="title has-text-grey-darker is-1"
                style={{ marginBottom: 50 }}
              >
                {frontmatter.section2.title}
              </h1>
              <h2 className="subtitle has-text-grey-light is-size-4">
                {frontmatter.section2.content}
              </h2>
            </div>
          </div>
        </section>
        <hr style={{ margin: 0 }} />
        <section className="hero">
          <div className="has-text-centered is-vcentered">
            <iframe
              style={{ height: "20rem", marginTop: 30, padding: 10 }}
              width="560"
              src="https://www.youtube-nocookie.com/embed/9mcwuvU1l3o?controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>
        <hr />
        <section>
          <div className="image-text-container">
            <div className="image-text-container_image">
              <Img
                className="image-text-container_image_image"
                fluid={frontmatter.section3.img.childImageSharp.fluid}
              />
            </div>
            <div className="image-text-container_text">
              <div className="title">{frontmatter.section3.title}</div>
              <div className="subtitle">
                <span
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.section3.content,
                  }}
                />
              </div>
              <div className="call-to-action">
                <Link
                  state={{
                    showUpgrade: true
                  }}
                  to={frontmatter.callToAction.uri}
                  onClick={() => {
                    if (process.env.NODE_ENV === "production") {
                      if (window.fbq) {
                        window.fbq("track", "CustomizeProduct", {
                          value: 39.99,
                          currency: "USD",
                        })
                      }
                    }
                  }}
                >
                  <button className="button is-primary  is-large has-text-centered">
                    {frontmatter.callToAction.title}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="image-text-container reverse">
            <div className="image-text-container_image">
              <Img
                className="image-text-container_image_image"
                fluid={frontmatter.section4.img.childImageSharp.fluid}
              />
            </div>
            <div className="image-text-container_text">
              <div className="title">{frontmatter.section4.title}</div>
              <div className="subtitle">
                <span
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.section4.content,
                  }}
                />
              </div>
              <div className="call-to-action">
                <Link
                  state={{
                    showUpgrade: true
                  }}
                  to={frontmatter.callToAction.uri}
                  onClick={() => {
                    if (process.env.NODE_ENV === "production") {
                      if (window.fbq) {
                        window.fbq("track", "CustomizeProduct", {
                          value: 39.99,
                          currency: "USD",
                        })
                      }
                    }
                  }}
                >
                  <button className="button is-primary  is-large has-text-centered">
                    {frontmatter.callToAction.title}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <hr style={{ margin: 0 }} />
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1
                className="title is-uppercase	is-4"
                style={{ marginBottom: 50 }}
              >
                VARIETY OF BACKGROUNDS
              </h1>
              <h2 className="subtitle has-text-grey-light">
                Teleport your pooch into one of many locations.
              </h2>
              <hr
                className="has-text-centered"
                width="100"
                align="center"
                style={{
                  backgroundColor: "black",
                  height: "3px",
                  border: 0,
                  margin: "auto",
                }}
              />
              <div
                className="columns is-multiline is-centered"
                style={{ marginTop: 10 }}
              >
                <div className="column">
                  <div className="card" style={{ borderRadius: 10 }}>
                    <div className="card-image">
                      <figure className="image">
                        <Img
                          fluid={beach.childImageSharp.fluid}
                          style={{ borderRadius: "10px 10px 0 0 " }}
                        />
                      </figure>
                    </div>
                    <div className="card-content">
                      <div className="content">
                        <h4 className="has-text-centered has-text-grey-dark">
                          SANDY BEACH
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card" style={{ borderRadius: 10 }}>
                    <div className="card-image">
                      <figure className="image">
                        <Img
                          fluid={sunset.childImageSharp.fluid}
                          style={{ borderRadius: "10px 10px 0 0 " }}
                        />
                      </figure>
                    </div>
                    <div className="card-content">
                      <div className="content">
                        <h4 className="has-text-centered has-text-grey-dark">
                          SUNSET HILL
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card" style={{ borderRadius: 10 }}>
                    <div className="card-image">
                      <figure className="image">
                        <Img
                          fluid={prehistoric_jungle.childImageSharp.fluid}
                          style={{ borderRadius: "10px 10px 0 0 " }}
                        />
                      </figure>
                    </div>
                    <div className="card-content">
                      <div className="content">
                        <h4 className="has-text-centered has-text-grey-dark">
                          PREHISTORIC JUNGLE
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                state={{
                  showUpgrade: true
                }}
                to={frontmatter.callToAction.uri}
                onClick={() => {
                  if (process.env.NODE_ENV === "production") {
                    if (window.fbq) {
                      window.fbq("track", "CustomizeProduct", {
                        value: 39.99,
                        currency: "USD",
                      })
                    }
                  }
                }}
              >
                <button
                  style={{ marginTop: "30px" }}
                  className="button is-primary  is-large has-text-centered"
                >
                  {frontmatter.callToAction.title}
                </button>
              </Link>
            </div>
          </div>
        </section>
        <hr />
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1
                className="title is-uppercase	is-4"
                style={{ marginBottom: 50 }}
              >
                HOW IT WORKS
              </h1>
              <hr
                className="has-text-centered"
                width="100"
                align="center"
                style={{
                  backgroundColor: "black",
                  height: "3px",
                  border: 0,
                  margin: "auto",
                }}
              />
              <div
                className="columns is-multiline is-centered"
                style={{ marginTop: 10 }}
              >
                <div className="column">
                  <div className="card" style={{ borderRadius: 10 }}>
                    <div className="card-image">
                      <figure className="image is-4by3">
                        <img src={freelancer} />
                      </figure>
                    </div>
                    <div className="card-content" style={{ height: 280 }}>
                      <div className="content">
                        <h4 className="has-text-centered has-text-grey-dark">
                          PLACE YOUR ORDER
                        </h4>
                        <p className="has-text-centered">
                          When placing your order, upload a photo of your pet
                          and select from a{" "}
                          <b style={{ color: "rgb(2, 176, 241)" }}>variety</b>{" "}
                          of available background options. We will take care of
                          the rest.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card" style={{ borderRadius: 10 }}>
                    <div className="card-image">
                      <figure className="image is-4by3">
                        <img src={focus} />
                      </figure>
                    </div>
                    <div className="card-content" style={{ height: 280 }}>
                      <div className="content">
                        <h4 className="has-text-centered has-text-grey-dark">
                          WE SKETCH YOUR PET
                        </h4>
                        <p className="has-text-centered">
                          We will transform your pet into a unique hand-drawn
                          digital portrait drawn by our artists.{" "}
                          <b style={{ color: "rgb(2, 176, 241)" }}>
                            Guaranteed to stand out!
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card" style={{ borderRadius: 10 }}>
                    <div className="card-image">
                      <figure className="image is-4by3">
                        <img src={happy_birthday_gift} />
                      </figure>
                    </div>
                    <div className="card-content" style={{ height: 280 }}>
                      <div className="content">
                        <h4 className="has-text-centered has-text-grey-dark">
                          WE SHIP YOUR ITEM
                        </h4>
                        <p className="has-text-centered">
                          When our artists finalize your design, we will print
                          it on your item of choice. We will ship it to you and
                          you should have it on your hands within{" "}
                          <b style={{ color: "rgb(2, 176, 241)" }}>
                            a couple of days!
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                state={{
                  showUpgrade: true
                }}
                to={frontmatter.callToAction.uri}
                onClick={() => {
                  if (process.env.NODE_ENV === "production") {
                    if (window.fbq) {
                      window.fbq("track", "CustomizeProduct", {
                        value: 39.99,
                        currency: "USD",
                      })
                    }
                  }
                }}
              >
                <button
                  style={{ marginTop: "30px" }}
                  className="button is-primary  is-large has-text-centered"
                >
                  {frontmatter.callToAction.title}
                </button>
              </Link>
            </div>
          </div>
        </section>
        <hr />
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div className="container is-fluid has-text-centered is-multiline">
              <h1
                className="title is-uppercase	is-4"
                style={{ marginBottom: 50 }}
              >
                OUR CUSTOMERS
              </h1>
              <hr
                className="has-text-centered"
                width="100"
                align="center"
                style={{
                  backgroundColor: "black",
                  height: "3px",
                  border: 0,
                  margin: "auto",
                  marginBottom: 30,
                }}
              />

              {frontmatter.customerFeature && <div className="columns is-vcentered is-centered">
                <div className={`column is-4`}>
                  <figure>
                    <Img
                      fluid={frontmatter.customerFeature.childImageSharp.fluid}
                      style={{ borderRadius: "10px 10px 10px 10px" }}
                    />
                  </figure>
                </div>
              </div>}

              <div className="columns is-vcentered is-centered is-multiline">
                {customers.nodes.map(node => <div key={node.id} className={`column is-${this.props.pageContext.customerSize}`}>
                  <div className="card" style={{ borderRadius: 10 }}>
                    <div className="card-image">
                      <figure className="image">
                        <Img
                          fluid={node.childImageSharp.fluid}
                          style={{ borderRadius: "10px 10px 0 0 " }}
                        />
                      </figure>
                    </div>
                  </div>
                </div>)}
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h3 className="is-size-1 has-text-centered">FAQ</h3>
            <hr
              className="has-text-centered"
              width="100"
              align="center"
              style={{
                backgroundColor: "black",
                height: "3px",
                border: 0,
                margin: "auto",
              }}
            />

            <Accordion className="accordion">
              {faqs.map(faq => (
                <div
                  key={`${faq.question}${Date.now()}`}
                  className="accordion-item"
                  data-header={faq.question}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: faq.answer,
                    }}
                  />
                </div>
              ))}
            </Accordion>
          </div>
        </section>
        <hr />
        <section>
          <Reviews handle={this.props.pageContext.handle} />
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query($handle: String, $shopifyHandle: String, $customers: String) {
    product: markdownRemark(frontmatter: { handle: { eq: $handle } }) {
      frontmatter {
        handle
        customerFeature {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(height: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        seo {
          title
          description
        }
        callToAction {
          title
          uri
        }
        section1 {
          title
          content
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section2 {
          title
          content
        }
        section3 {
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          content
        }
        section4 {
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          content
        }
        faq {
          question
          answer
        }
      }
    }
    shopifyProduct(handle: { eq: $shopifyHandle }) {
      shopifyId
      title
      variants {
        id
        availableForSale
        price
        selectedOptions {
          name
          value
        }
        shopifyId
      }
      options {
        id
        name
        values
      }
      priceRange {
        minVariantPrice {
          amount
        }
      }
      images {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        originalSrc
      }
    }
    shopifyCollection(
      products: { elemMatch: { handle: { eq: $shopifyHandle } } }
    ) {
      title
    }
    beach: file(relativePath: { eq: "backgrounds/jack/beach.png" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sunset: file(relativePath: { eq: "backgrounds/jack/sunset.png" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    prehistoric_jungle: file(
      relativePath: { eq: "backgrounds/jack/prehistoric_jungle.png" }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sketch: file(relativePath: { eq: "sketch.jpg" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    order: file(relativePath: { eq: "order.jpg" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shipping: file(relativePath: { eq: "shipping.jpg" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    customers: allFile(filter: { relativeDirectory: { eq: $customers }}, sort: {order: ASC, fields: name}) {
      nodes {
        id
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default ProductTemplate
